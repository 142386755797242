*{
    box-sizing: border-box;
}
body{
    margin: 0%;
}
/* Add this to your existing Navbar.css file */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: blue;
    color: white;
    padding: 0 3rem;
  }
  
  .nav a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }
  
  .nav li:active {
    background-color: red;
  }
  
  .nav li:hover {
    background-color: white;
    color: blue;
  }
  
  .site-title {
    font-size: 2rem;
  }
  
  .nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  /* Add this to your existing Navbar.css file */
  .hamburger-menu {
    display: none;
  }
  
  .hamburger {
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .bar {
    width: 100%;
    height: 3px;
    background-color: blue;
    transition: transform 0.3s ease;
  }
  
  .open .bar:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  
  .open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .open .bar:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  @media screen and (max-width: 768px) {
    /* Hide the site title and the list of links */
    .nav .site-title,
    .nav ul {
      display: none;
    }
  
    /* Show the hamburger menu */
    .hamburger-menu {
      display: block;
      background-color:white;
      padding: 1rem 1rem;
      
      
      
    }
  
    .hamburger-menu:hover {
      background-color: rgb(229, 229, 242);
      color: white;
    }
  
    .nav-links.open {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .nav-links.open li {
      margin-bottom: 1rem;
    }
  }
  