/* Admin.css */

.admin-container {
    margin-top: 50px;
    background-color: white;
    color:blue;
    padding: 20px;
    text-align: center; 
}

.admin-title {
    margin-bottom: 20px;
    font-size: 24px; 
}

.admin-links {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.admin-link {
    margin-top: 10px;
    color: black;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 10px 20px; 
    border: 1px solid black;
    border-radius: 5px; 
    transition: background-color 0.3s, color 0.3s; 
}

.admin-link:hover {
    background-color: white; 
    color: blue;
}
