.connectContainer {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 80px;
    margin-top: 30px;
    width: 80%;
    margin-left:80px ;
    background: #007bff;
    color: #fff;
  }
  .contactButton {
    background-color: #fff;
    color: #007bff;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contactButton:hover {
    background-color: #f0f0f0;
  }
  li{
    list-style: none;
  }
  @media screen and (max-width: 768px){
    .connectContainer{
      margin-left: 50px;
      text-align: center;
    }
 
  }
  