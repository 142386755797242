/* Login.css */

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.login-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.login-container input[type="email"],
.login-container input[type="password"] {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.login-container input[type="email"]:focus,
.login-container input[type="password"]:focus {
  border-color: #007bff;
}

.login-container button {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #0056b3;
}
