/* About.css */
.imgContainer {
  padding-top: 100px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
.myprofilepic {
    border-radius: 50%; 
    box-shadow: 0px 0px 10px rgba(131, 136, 138, 0.5);
    width: 150px; 
    height: 150px; 
  }
/* Styles for the "Send me an email" button */
.contactButtonAbout {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contactButtonAbout:hover {
    background-color: #0056b3;
  }
  
  /* Styles for the social media links */
  .social-media-links-About {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #0056b3;  */
    padding: 10px;
    margin: 40px;
    gap: 2rem;
    
  }
  
  .social-media-links-About a {
    margin: 0 10px;
    color:blue;
    font-size:25px;
  }
  
  .social-media-links-About a:hover {
    color: white  ;
    background-color: #0056b3;
  }
  