.create-project-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.createProjectTitle {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.projectForm label {
  font-weight: bold;
}

.projectForm input[type="text"],
.projectForm textarea,
.projectForm select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.projectForm textarea { 
  width: 100%;
  height: 200px;
}

.projectForm input[type="file"] {
  margin-bottom: 20px;
  border: 1px dotted;
  width: 100%;
  padding: 10px;
}

.projectForm button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.projectForm button:hover {
  background-color: #45a049;
}

.projectForm input[type="checkbox"] {
  margin-right: 5px;
  
}

.projectForm .other-technology-container {
  margin-top: 10px;
}

.projectForm .other-technology-container input[type="text"] {
  width: calc(100% - 100px);
}

.projectForm .other-technology-container button {
  margin-left: 10px;
}

.technologiesCheckbox{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 10px;
  border: 1px solid;
  width: 100%;
  padding: 10px;
}
.technologies{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}
.otherTechnology{
  display: flex;
  justify-content: space-evenly;

}