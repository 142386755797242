.skillsContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 80%;
    margin-left: 80px;
  
  }
  
  .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
   
  }
  
  
  .tag {
    display: flex;
    align-items: center;
    background-color: blue;
    border-radius: 20px;
    padding: 5px 10px;
  }
  
 
  .tag .icon {
    font-size: 1.2em;
    margin-right: 5px;
  }
  
  
  .tag .skill-name {
    font-size: 1em;
    color: white;
  }
  

  .projects-container{
    width: 80%;
    margin-left:80px ;
  }
  .skillTitle{
    color: blue;
}
 
  @media screen and (max-width: 768px) {
    .tag-container{
      display: grid;
      grid-template-columns: repeat(2,1fr) ;
      gap: 10px;
      padding: 10px;
      margin-bottom: 30px;
    }
    .skillsContainer{
      padding: 20px;
      margin-left:50px;
      
    }
  }