.not-found-container {
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .not-found-title {
    color: #05386b;
  }
  
  .not-found-message {
    color: #05386b;
  }
  
  .not-found-button {
    background-color:blue;
    color: #edf5e1;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
.url{
    color:blue;
    font-weight: bold;
    font-size: 20px;
}