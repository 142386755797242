.footer {
    padding: 20px;
    text-align: center;
    height: 25vh;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    width: 80%;
    margin-left:80px ;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .made-with-love {
    font-size: 20px;
  }
  
  .social-media-links {
    font-size: 24px;
  }
  
  .social-media-links .icon {
    margin: 0 10px;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .social-media-links .icon:hover {
    color: #f0f0f0;
  }
  
  .back-to-top {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
  }
  
  .back-to-top:hover {
    color: #f0f0f0;
  }
  
  @media screen and (max-width: 768px){
    .footer{
      margin-left: 50px;
    }
    .footer-content{
      display: flex;
      flex-direction: column;
      text-align: center;

    }
 
  }