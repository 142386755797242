.project-card {
    background-color:blue;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    width: calc(33.33% - 40px); /* Three cards per row with some margin between */
    display: inline-block;
    vertical-align: top;
}

@media (max-width: 768px) {
    .project-card {
        display: grid;
        width: 98%;
        margin-left: -24px;


    }
}

.card-header {
    position: relative;
}

.project-banner {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.project-name {
    color:white;
    text-transform: capitalize;
}

.project-description {
    color: white;
}

.project-details {
    margin-top: 10px;
}

.project-links {
    margin-top: 20px;
}

.btn {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.btn-primary {
    background-color: #fff;
    color: blue;
}

.btn-secondary {
    background-color: #6c757d;
}

.btn:hover {
    opacity: 0.8;
}

.meta-details{
    color:#fff;
    text-transform: capitalize;
}
.projectTitle{
    color: blue;
}